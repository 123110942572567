<template>
  <div class="container">
    <div class="titleImg">
      <img src="../../assets/img/code/code1.png" alt="" width="100%">
      <div @click="$router.push('/OwnWorkbench/QRManagement')" class="toCode">
        <!-- <el-button @click="$router.push('/OwnWorkbench/QRManagement')" class="setCode">在线生成二维码</el-button> -->
      </div>
    </div>
    <div style="text-align: center">
      <img src="../../assets/img/code/code2.png" alt="">
    </div>
    <div style="text-align: center">
      <img src="../../assets/img/code/code3.png" alt="">
    </div>
    <div style="text-align: center">
      <img src="../../assets/img/code/code4.png" alt="">
    </div>
    <div style="text-align: center">
      <el-button type="primary" round style="width: 10%" @click="$router.push('/OwnWorkbench/QRManagement')">免费使用</el-button>
    </div>
    <div style="text-align: center">
      <img src="../../assets/img/code/code5.png" alt="">
    </div>
    <div style="text-align: center">
      <el-button type="primary" round style="width: 10%; margin-top: 30px" @click="$router.push('/OwnWorkbench/QRManagement')">免费使用</el-button>
    </div>
    <div style="text-align: center">
      <img src="../../assets/img/code/code6.png" alt="">
    </div>
    <div style="text-align: center">
      <img src="../../assets/img/code/code7.png" alt="">
    </div>
    <div style="text-align: center">
      <el-button type="primary" round style="width: 10%; margin: 30px 0 100px 0" @click="isVisible = true">了解详情</el-button>
    </div>

    <el-dialog title="联系我们" :visible.sync="isVisible" width="35%" center>
      <div style="text-align: center; margin-bottom: 50px">简单填写信息，我们的运营人员稍后免费为你一对一服务</div>
      <el-form :model="form" ref="form" label-width="120px" :rules="rules">
        <el-form-item label="称呼：" prop="nickname">
          <el-input placeholder="如: 黄先生" v-model="form.nickname" maxlength="20" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="手机号码：" prop="phone">
          <el-input placeholder="请输入手机号码" v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="需求：" prop="requirementDesc">
          <el-input type="textarea" :rows="5" resize="none" placeholder="简单描述您的需求" v-model="form.requirementDesc" maxlength="500" show-word-limit></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="isVisible = false">取消</el-button>
        <el-button type="primary" @click="submit">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { saveContact } from '@/api/qrCode'

export default {
	data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入手机号'));
      } else {
        var reg=/^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (reg.test(value)) {
            callback();
        } else {
            callback(new Error('请输入正确的手机号'));
        }
      }
    };
		return {
			isVisible: false,
      form: {
        nickname: '',
        phone: '',
        requirementDesc: ''
      },
      rules: {
        nickname: [
          { required: true, message: '请输入称呼', trigger: 'blur' },
        ],
        phone: [
          { required: true, validator: validatePass, trigger: 'blur' }
        ],
        requirementDesc: [
          { required: true, message: '请输入需求描述', trigger: 'blur' }
        ]
      }
		}
	},
  methods: {
    submit () {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const res = await saveContact(this.form)
          if(res.code == 0) {
            this.isVisible = false
            this.$message.success('保存成功')
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .container{
    .setCode {
      margin: 20px;
    }
    .titleImg {
      position: relative;
      .toCode {
        position: absolute;
        top: 58%;
        left: 6%;
        width: 190px;
        height: 60px;
        cursor: pointer;
      }
    }
  }
</style>